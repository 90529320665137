<template>
  <v-card class="pa-4 m-auto">
    <v-toolbar width="100%" class="mx-0" color="primary " dark>
      <v-card-text class="text-h5"> Template Update</v-card-text>
      <v-spacer> </v-spacer>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- {{ item }} -->
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="template.title"
          :rules="$requiredRules"
          label="Title"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-textarea
          v-model="template.content"
          :rules="$requiredRules"
          label="Content"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn color="primary" outlined @click="update">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  emits: ["closeDialog"],

  data: () => ({
    template: {
      title: "",
      content: "",
    },
  }),
  // async created() {
  //   await this.loadtemplate();
  // },
  mounted() {
    this.loadtemplate();
  },
  methods: {
    loadtemplate() {
      this.$api
        .get(`/template/${this.item.id}`)
        .then((response) => {
          this.template = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update() {
      this.$api
        .put(`/template/${this.item.id}/`, this.template)
        .then((res) => (this.template = res.data))
        .then(this.$emit("closeDialog"));

      // .then((r) => console.log(r));
    },
  },
};
</script>

<style></style>
